<template>
  <div class="main-paper">
    <div class="top-bar">
      
    </div>
    <div class="navigation-left">
      <div class="person-info">
        
      </div>
    </div>
    <div class="content-right">

    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    colorTest: String,
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
