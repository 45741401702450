<template>
  <div class="top-nav">
    <div class="top-nav-item">
      
    </div>
  </div>
  <HelloWorld msg="Welcome to Your Vue.js App" :colorTest="colorTest"/>
  <!-- <DraggableModules/> -->
  <div class="record-number"><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023022121号-1</a></div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
// import DraggableModules from './views/DraggableModules.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    // DraggableModules
  },
  data() {
    return {
      colorTest: '#111'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.record-number{
  position: absolute;
  bottom: 20px;
  left: calc(50% - 88px);
}
</style>
